<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="all-reports"
              :load-dictionaries="loadDictionaries"
              :loaded="filteringPanel.loaded"
              :show-labels="false"
              :hide-custom-filters="true"
              :filters="filteringPanel.filters"
              @dict-loaded="onFilteringPanelLoad"
              @search="getData"
              @change="filterData"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <reports-table
              ref="reports-table"
              filter="my"
              @toggle-view="onToggleView"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";

import ReportsTable from "@/views/Reports/ReportsTable";

import { mapState, mapActions } from "vuex";

export default {
  name: "MyReports",
  components: {
    FilteringPanel,
    ReportsTable
  },
  data: function() {
    return {
      isLoading: false,
      filteringPanel: {
        loaded: false,
        selected: {
          department: {
            id: "",
            label: ""
          },
          category: {
            id: "",
            label: ""
          }
        },
        categoriesMapped: [],
        filters: [
          {
            type: "select",
            title: "Department",
            name: "department",
            trackby: "id",
            label: "label",
            options: [],
            dataType: "string",
            selected: {}
          },
          {
            type: "select",
            title: "Report Name",
            name: "category",
            trackby: "id",
            label: "label",
            options: [],
            dataType: "string",
            selected: {}
          },
          {
            type: "select",
            title: "Frequency",
            name: "frequency",
            trackby: "id",
            label: "label",
            options: [],
            dataType: "string",
            selected: {}
          },
          {
            type: "select",
            title: "Reviewed",
            name: "reviewed",
            trackby: "id",
            label: "label",
            options: this.$form.yesNo.options,
            dataType: "string",
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Status",
            tooltip: "Report status",
            name: "status",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [
              { id: 0, label: "Active" },
              { id: 1, label: "Deleted" }
            ],
            selected: {}
          },
          {
            type: "daterange",
            title: "Report date",
            dataType: "datetime",
            name: "report-date"
          }
        ]
      }
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  mounted() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    getData() {
      //let self = this;

      let fp = this.$refs.filteringPanel;

      if (!fp.isSearchAllowed()) return;

      let payload = {};

      /*
               if (fp.panelState.isCustomFiltersBar) {
                   payload.expression = JSON.stringify(fp.prepareExpressions(f['custom-filter']))
               }
            */
      this.$refs["reports-table"].getData(payload);
    },
    async loadDictionaries() {
      let self = this;

      const categories = () => {
        this.$api.get("users/reports/categories/mapped").then(response => {
          self.categoriesMapped = response;

          self.filteringPanel.filters.find(
            f => f.name === "department"
          ).options = self.$helpers.getDistinctArray(
            response,
            "dep_id",
            "dep_name",
            "id",
            "label"
          );
        });
      };

      const frequencies = () => {
        this.$api.get("users/reports/frequencies").then(response => {
          self.filteringPanel.filters.find(
            f => f.name === "frequency"
          ).options = response.map(u => ({
            id: u.id,
            label: u.name
          }));
        });
      };

      Promise.all([categories(), frequencies()]).then(
        () => (this.filteringPanel.loaded = true)
      );
    },

    onFilteringPanelLoad() {
      this.$refs["reports-table"].getData();
    },
    onCustomFilterSelected() {
      // if (e.data) this.onSelectedExpression(e)
    },
    /*
                async onSelectedExpression(e) {

                    //if selected expression is empty then apply usual filters
                    if (!e || !e.data) {

                        this.filterData(this.filteringPanel.selected);
                        return;
                    }

                    let rawData = this.$refs["projects"].rawData;

                    if (rawData.length === 0) {
                        await this.$form.msgBoxOk("No data available!");
                        return;
                    }
                    let expressions = JSON.parse(e.data);

                    if (!expressions) {
                        self.$form.makeToastError("Invalid expression");
                        return;
                    }

                    let filteredData = this.$helpers.applyExpressions2Dataset(expressions, rawData);

                    this.$refs["projects"].dataTable.dataSet = filteredData;

                    this.presetFilteredData = filteredData;

                },*/
    onFilteringPanelStateChange() {},
    onToggleView() {
      const f = this.$refs["filteringPanel"].selected;

      this.filterData(f);
    },
    filterData(e) {
      this.filteringPanel.selected = e;

      if (!this.$refs["reports-table"].rawData.length) return;

      let filteredData = this.$refs["reports-table"].rawData;

      if (this.filteringPanel.selected != undefined) {
        filteredData = filteredData.filter(i =>
          this.filteringPanel.selected.department
            ? i["Department"] === this.filteringPanel.selected.department.label
            : true
        );

        filteredData = filteredData.filter(i =>
          this.filteringPanel.selected.category
            ? i["Report Name"] === this.filteringPanel.selected.category.label
            : true
        );

        filteredData = filteredData.filter(i =>
          this.filteringPanel.selected.frequency
            ? i["Frequency"] === this.filteringPanel.selected.frequency.label
            : true
        );

        filteredData = filteredData.filter(i =>
          this.filteringPanel.selected.reviewed
            ? i["Reviewed"] === this.filteringPanel.selected.reviewed.label
            : true
        );

        //my submits mode
        filteredData = filteredData.filter(i =>
          this.$refs["reports-table"].currentView.mode === 2
            ? i["Submitter_id"] === this.profile.data.id.toString()
            : true
        );

        let selectedStatuses = this.filteringPanel.selected?.status
          ? this.filteringPanel.selected.status.map(s => s.label)
          : [];

        filteredData = filteredData.filter(i =>
          selectedStatuses.length && i["Status"]
            ? selectedStatuses.includes(i["Status"])
            : true
        );

        filteredData = filteredData.filter(i =>
          this.filteringPanel.selected["report-date"]
            ? new Date(i["Report Date"]) >=
                new Date(
                  this.filteringPanel.selected["report-date"].startDate
                ) &&
              new Date(i["Report Date"]) <=
                new Date(this.filteringPanel.selected["report-date"].endDate)
            : true
        );
      }

      this.$refs["reports-table"].dataTable.dataSet = filteredData;
    }
  },
  watch: {
    //called from filterData
    "filteringPanel.selected.department": function(newVal, oldVal) {
      if (
        newVal &&
        newVal.id &&
        JSON.stringify(newVal) !== JSON.stringify(oldVal)
      ) {
        this.filteringPanel.filters.find(
          f => f.name === "category"
        ).options = this.$helpers.getDistinctArray(
          this.categoriesMapped.filter(s => s.dep_id === newVal.id),
          "cat_id",
          "cat_name",
          "id",
          "label"
        );
      }
    }
  }
};
</script>

<style>
.fa-toolbar {
  color: #0000008a;
}

.preview-report {
  height: 500px;
  width: 100%;
  overflow-y: scroll;
}
</style>
